import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { update_user_profile } from "../../actions/User";

const Settings = (props) => {
  // Get UserSettings from State
  const UserSettings = useSelector((state) => state.User);

  const dispatch = useDispatch();

  const handleSettingsChange = (event) => {
    dispatch(update_user_profile(event.target.id, event.target.value));
  };

  return (
    <>
    <div className="card p-3">
      <div className="card-body">
      <h1>Einstellungen</h1>
      <h4>User-Einstellungen</h4>
      <div className="row mb-5">
        <div className="col-6">
          <div className="form-group">
            <label>Username</label>
            <input
              type="text"
              id="userName"
              className="form-control"
              disabled
              value={UserSettings.userName}
              onChange={handleSettingsChange}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label>E-Mailadresse</label>
            <input
              type="text"
              id="email"
              className="form-control"
              value={UserSettings.email}
              onChange={handleSettingsChange}
            />
          </div>
        </div>
      </div>

      <h4>App-Einstellungen</h4>
      <div className="row mb-5">
        <div className="col-6">
          <div className="form-group">
            <label>Sync-Server</label>
            <input
              type="text"
              id="syncServer"
              className="form-control"
              value={UserSettings.syncServer}
              onChange={handleSettingsChange}
            />
          </div>
        </div>

        <div className="col-6">
          <div className="form-group">
            <label>Sync-Token (App-Kennwort)</label>
            <input
              type="password"
              id="syncToken"
              className="form-control"
              value={UserSettings.syncToken}
              onChange={handleSettingsChange}
            />
          </div>
        </div>
        </div>
    </div>
      </div>
    </>
  );
};

export default Settings;
