// Get Name of current day (a little dirty for now)
// @todo - use values from moment.js
export const getDayName = (number) => {
    let dayNames = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
    return dayNames[number];
}

// Returns the name of a slot in our standardized format
// @todo - seperate this in an own module
export const getSlotName = (dateObject, username, slotNumber) => {
    let day = 0;

    //days
    if ((dateObject.getDate() + 1) <= 10) {
        day = "0" + "" + (dateObject.getDate());
    } else {
        day = dateObject.getDate();
    }

    let month = 0;

    //months
    if ((dateObject.getMonth() + 1) < 10) {
        month = "0" + "" + (dateObject.getMonth() + 1);
    } else {
        month = (dateObject.getMonth() + 1 );
    }

    return (dateObject.getFullYear() + "-" + month + "-" + day + "-" + username + "-" + slotNumber);
}


// Returns the name of a slot in our standardized format
// @todo - seperate this in an own module
export const getSlotDate = (dateObject) => {

    let day = 0;
    //days
    if ((dateObject.getDate() + 1) < 10) {
        day = "0" + "" + (dateObject.getDate());
    } else {
        day = dateObject.getDate();
    }

    let month = 0;
    //months
    if ((dateObject.getMonth() + 1) < 10) {
        month = "0" + "" + (dateObject.getMonth() + 1);
    } else {
        month = dateObject.getMonth() + 1;
    }

    return (dateObject.getFullYear() + "-" + month + "-" + day);
}

export const copy = (value) => {
    if(typeof(value) == "object"){
        return JSON.parse(JSON.stringify(value));
    }else {
        return value;
    }
}

export const getSlotMetaFromId = (slotId) => {
    let slotIdSplitted = slotId.split("-");
    return {
        slotId : slotId,
        slotUser : slotIdSplitted[3],
        slotDate : slotIdSplitted[0] + "-" + slotIdSplitted[1] + "-" + slotIdSplitted[2],
        slotNumber : slotIdSplitted[4]
    }
}