import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'moment/locale/de';
import stylelist from "stylelist";
import { useHistory, useParams, Link } from "react-router-dom";
import { add_new_slot, delete_slot, update_slot } from "../../actions/Slots";
import { copy, getSlotMetaFromId } from "../../libs/SlotUtils";
import UserSelector from "./UserSelector";

import SlotInputField from "./SlotInputField";


const AddSlot = (props) => {

    const Slots = useSelector((state) => state.Slot);
    const Team  = useSelector((state) => state.Team);

    const history  = useHistory();
    const params   = useParams();
    const dispatch = useDispatch();

    const [newOrEdit,                  setNewOrEdit]                   = useState("new");
    const [hideArgumentFields,         setHideArgumentFields]          = useState(false);
    const [slotId,                     setSlotId]                      = useState("");
    const [slotHasChangedFundamentally,setSlotHasChangedFundamentally] = useState(false);
    const [oldSlotId,                  setOldSlotId]                   = useState("");
    const [slotUser,                   setSlotUser]                    = useState("test");
    const [slotType,                   setSlotType]                    = useState("simple");
    const [slotWeclappOrderId,         setSlotWeclappOrderId]          = useState("A");
    const [slotTitle,                  setSlotTitle]                   = useState("");
    const [slotDescription,            setSlotDescription]             = useState("");
    const [slotDate,                   setSlotDate]                    = useState("2021-01-26");
    const [slotNumber,                 setSlotNumber]                  = useState(1);
    const [slotOwner,                  setSlotOwner]                   = useState("teamleader");
    const [message,                    setMessage]                     = useState("");
    const [slotExpensesBooked,         setSlotExpensesBooked]          = useState(false)

    /**
     * init-effect
     * Is being executed only once (!) and checks what we should do
     */
    const init = useEffect(() => {
        // When a slotId is given via prop - this is the quickview
        if (props.slotId) {
            loadSlotMeta(getSlotMetaFromId(props.slotId));
            setNewOrEdit("new");
            setHideArgumentFields(true);
        }

        // SlotID is given via URL - we are in edit view
        if (params.slotId) {

            // Check if the ID exists
            if (typeof (Slots[params.slotId]) == "undefined") {
                history.push("/dashboard");
            }

            loadSlotMeta(getSlotMetaFromId(params.slotId));
            setNewOrEdit("edit");
        }
    }, []);

    const dynamicallyUpdatetitle = useEffect(() => {
        if (newOrEdit == "new") {
            if (slotType == "sick") {
                setSlotTitle("Krank");
            }

            if (slotType == "holiday") {
                setSlotTitle("Urlaub");
            }

            if (slotType == "school") {
                setSlotTitle("Berufsschule");
            }
        }
    }, [slotType])

    // Sets the meta-data by an given slotmeta-mobject
    const loadSlotMeta = (slotMetaObject) => {
        setSlotId(copy(slotMetaObject.slotId));
        setOldSlotId(copy(slotMetaObject.slotId));
        setSlotUser(copy(slotMetaObject.slotUser));
        setSlotDate(copy(slotMetaObject.slotDate));
        setSlotNumber(copy(slotMetaObject.slotNumber));
        setSlotOwner(copy(slotMetaObject.slotOwner));
    }

    // When we are in edit-mode, load the Content when we have the slot-id
    const loadSlotContentFromStorage = useEffect(() => {
        if (typeof (Slots[slotId]) == "object" && newOrEdit == "edit") {
            setSlotType(copy(Slots[slotId].content.slotType));
            setSlotTitle(copy(Slots[slotId].content.slotTitle));
            setSlotDescription(copy(Slots[slotId].content.slotDescription));
            setSlotWeclappOrderId(copy(Slots[slotId].content.slotWeclappOrderId));
            setSlotOwner(copy(Slots[slotId].content.slotOwner));
            setSlotExpensesBooked(copy(Slots[slotId].content.slotExpensesBooked));
        }
    }, [slotId, newOrEdit]);


    // Update the Slot ID when some datas are changed
    const updateSlotId = useEffect(() => {
        let tempSlotId = slotDate + "-" + slotUser + "-" + slotNumber;
        setSlotId(tempSlotId);

        if (slotId != oldSlotId) {
            setSlotHasChangedFundamentally(true);
        } else {
            setSlotHasChangedFundamentally(false);
        }
    });

    const createSlot = () => {

        if(slotType == "weclapp" && (slotWeclappOrderId == "" || slotWeclappOrderId == "A")){
            setMessage("Die WeClapp-Auftragsnummer muss bei diesem Slottyp eingegeben werden.");
            return false;
        }

        if(slotTitle.slice(slotTitle.length - 4) == " // "){
            setMessage("Der Slotname ist ungültig. Bitte gebe eine Tätigkeit hinter »//« im Slottitel ein oder entferne »//« aus dem Titel.");
            return false;
        }

        let newSlot = {
            
            meta: {
                slotUser: slotUser,
                slotDate: slotDate,
                slotNumber: slotNumber,
            },
            
            content: {
                slotDescription: slotDescription,
                slotId: slotId,
                slotTitle: slotTitle,
                slotType: slotType,
                slotWeclappOrderId: slotWeclappOrderId,
                slotOwner : slotOwner,
                slotExpensesBooked : slotExpensesBooked,
            }
        }

        dispatch(add_new_slot(slotId, newSlot, true));

        // check if we are in popup-mode and should close the popup
        if (typeof (props.closeHandler) == "function") {
            props.closeHandler()
        } else {
            history.push("/calendar");
        }
    }

    const updateSlot = (deleteOld = false) => {

        if(slotType == "weclapp" && (slotWeclappOrderId == "" || slotWeclappOrderId == "A")){
            setMessage("Die WeClapp-Auftragsnummer muss bei diesem Slottyp eingegeben werden.");
            return false;
        }



        let newSlot = {
            meta: {
                slotUser: slotUser,
                slotDate: slotDate,
                slotNumber: slotNumber,
            },
            
            content: {
                slotDescription: slotDescription,
                slotId: slotId,
                slotTitle: slotTitle,
                slotType: slotType,
                slotWeclappOrderId: slotWeclappOrderId,
                slotOwner : slotOwner,
                slotExpensesBooked : slotExpensesBooked
            }
        }

        dispatch(update_slot(slotId, newSlot));

        // if a slot has changed fundamentally and we should delete the old one
        if (deleteOld == true) {
            dispatch(delete_slot(oldSlotId));
        }

        // check if we are in popup-mode and should close the popup
        if (typeof (props.closeHandler) == "function") {
            props.closeHandler()
        } else {
            history.push("/calendar");
        }
    }

    return (
        <>
            <div className="card p-3 pt-1">
                <div className="card-body">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-12">
                                <h6 className="">Titel</h6>
                                <SlotInputField value={slotTitle} onChange={(value) => { setSlotTitle(value) }} />

                            </div>

                            </div>

                            <div className="row mb-3">

                                <div className="col-md-6">
                                    <h6 className="mt-4">Mitarbeiter</h6>
                                    <UserSelector value={slotUser} onChange={(username) => { setSlotUser(username) }} />
                                </div>
                            

                            <div className="col-md-6">
                                    <h6 className="mt-4">Product Owner</h6>
                                    <UserSelector role="administrator" value={slotOwner} onChange={(username) => { setSlotOwner(username) }} />
                                </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <div className="mb-5">
                                <h6>Slot-Typ</h6>
                            
                                <a className={stylelist("btn btn-sm mr-2 mb-2", [slotType == "simple", "btn-primary"], [slotType != "simple", "btn-secondary"])} onClick={() => { setSlotType("simple") }}>
                                    Simpel
                                </a> &nbsp;

                                {/* 
                                <a className={stylelist("btn btn-sm mr-2", [slotType == "weclapp", "btn-primary"], [slotType != "weclapp", "btn-secondary"])} onClick={() => { setSlotType("weclapp") }}>
                                    WeClapp
                                </a> &nbsp;

                                <a className={stylelist("btn btn-sm mr-2", [slotType == "trello", "btn-primary"], [slotType != "trello", "btn-secondary"])} onClick={() => { setSlotType("trello") }}>
                                    Trello
                                </a> &nbsp;*/}

                                <a className={stylelist("btn btn-sm mr-2 mb-2", [slotType == "holiday", "btn-primary"], [slotType != "holiday", "btn-secondary"])} onClick={() => { setSlotType("holiday") }}>
                                    Urlaub
                                </a> &nbsp;

                                <a className={stylelist("btn btn-sm mr-2 mb-2", [slotType == "sick", "btn-primary"], [slotType != "sick", "btn-secondary"])} onClick={() => { setSlotType("sick") }}>
                                    Krank
                                </a> &nbsp;

                                <a className={stylelist("btn btn-sm mr-2 mb-2", [slotType == "support", "btn-primary"], [slotType != "support", "btn-secondary"])} onClick={() => { setSlotType("support") }}>
                                    Support
                                </a> &nbsp;

                                <a className={stylelist("btn btn-sm mr-2 mb-2", [slotType == "school", "btn-primary"], [slotType != "school", "btn-secondary"])} onClick={() => { setSlotType("school") }}>
                                    Schule
                                </a> &nbsp;
                            </div>
                            </div>

                            <div className="col-6">
                                <div className="mb-5">
                                <h6>Aufwände gebucht</h6>
                                <a className={stylelist("btn btn-sm mr-2 mb-2", [slotExpensesBooked, "btn-primary"], [!slotExpensesBooked, "btn-secondary"])} onClick={() => { setSlotExpensesBooked(true) }}>
                                    Ja
                                </a> &nbsp;

                                <a className={stylelist("btn btn-sm mr-2 mb-2",  [!slotExpensesBooked, "btn-primary"], [slotExpensesBooked, "btn-secondary"])} onClick={() => { setSlotExpensesBooked(false) }}>
                                    Nein
                                </a> &nbsp;
                                    </div>
                            </div>

                            </div>   

                   

                      

                    </div>

  
                    {/* show fields for weclapp-slot-type 
                    {slotType == "weclapp" &&
                        <>
                            <h6 className="mt-4">WeClapp-ID</h6>

                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <WeClappSelector weclappId={slotWeclappOrderId} onSetWeclappOrderId={(string) => {setSlotWeclappOrderId(string)}} />
                                </div>
                            </div>
                        </>
                    }*/}

                    {/* When we are editing a slot, we dont need this fields */}
                    {(newOrEdit == "new" && !hideArgumentFields ) &&
                        <>
                            <h6 className="mt-4">Datum</h6>
                            <div className="row mb-3">
                                <div className="col-md-8">
                                    <input className="form-control mb-3" value={slotDate} onChange={(event) => { setSlotDate(event.target.value) }} />
                                </div>
                            </div>

                            <h6 className="mt-4">Slot</h6>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <div className={stylelist("card cursor-pointer", [slotNumber == "1", "bg-light"])} onClick={() => { setSlotNumber("1") }}>
                                        <div className="card-body slot-type-card">
                                            <h6 className="card-title">Vormittags</h6>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className={stylelist("card cursor-pointer", [slotNumber == "2", "bg-light"])} onClick={() => { setSlotNumber("2") }}>
                                        <div className="card-body slot-type-card">
                                            <h6 className="card-title">Nachmittags</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    <h6 className="mt-2">Beschreibung</h6>
                    <div className="row mb-3">
                        <div className="col-md-8">
                            <textarea type="text" className="form-control" value={slotDescription} onChange={(event) => { setSlotDescription(event.target.value) }} rows="3" />
                            <small>Hinweis: Markdown ist aktiviert</small>
                        </div>
                    </div>


                    {message != "" &&
                        <div className="alert alert-danger" role="alert">
                             {message}
                        </div>
                    }

                    {(slotHasChangedFundamentally && newOrEdit == "edit") &&
                        <>
                            <div className="alert alert-danger" role="alert">
                                Der Slot wurde fundamental überarbeitet (Benutzer, Datum oder Slotnummer geändert).<br />

                            </div>

                            <button onClick={() => { updateSlot(true) }} className="btn btn-primary">Slot bearbeiten (alten Slot löschen)</button>&nbsp;
                                <button onClick={updateSlot} className="btn btn-info">Slot erstellen (alten Slot behalten)</button>
                        </>
                    }

                    {(newOrEdit == "edit" && !slotHasChangedFundamentally) &&
                        <>
                            <button onClick={updateSlot} className="btn btn-primary">Slot bearbeiten</button>&nbsp;
                            <Link to="/calendar" className="btn btn-primary">Zurück zum Kalender</Link>
                        </>
                    }

                    {(newOrEdit == "new") &&
                        <>
                            <button onClick={createSlot} className="btn btn-primary">Slot erstellen</button>
                        </>
                    }
                </div>
            </div>

        </>
    );
};

export default AddSlot;
