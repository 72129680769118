export const update_user_profile = (fieldName, fieldValue) => {
    return {
        type : "UPDATE_USER_PROFILE",
        payload : {
            fieldName: fieldName,
            fieldValue: fieldValue
        }
    }
}

export const set_current_calendar_week = (calendar_week) => {
    return {
        type : "SET_CURRENT_CALENDAR_WEEK",
        payload : calendar_week
    }
}

export const set_new_lazy_timespan = (from, to) => {
    return {
        type : "SET_NEW_LAZY_TIMESPAN",
        payload: {
            from: from,
            to: to
        }
    }
}

export const enable_beta = () => {
    return {
        type : "ENABLE_BETA"
    }
}


export const disable_beta = () => {
    return {
        type : "DISABLE_BETA"
    }
}


export const set_current_group = (group) => {
    return {
        type : "SET_CURRENT_GROUP",
        payload: group
    }
}

export const first_launch_done = () => {
    return {
        type : "FIRST_LAUNCH_DONE",
        payload : true
    }
}

export const set_expense_project_root_id = (id) => {
    return {
        type : "SET_EXPENSE_PROJECT_ROOT_ID",
        payload: id
    }
}