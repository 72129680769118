import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { add_section } from "../../actions/ExpenseBucket";
import ExpenseBucketSection from "./ExpenseBucketSection"

const ExpenseBucketModule = (props) => {
    const dispatch = useDispatch();
    const ExpenseBucket = useSelector((state) => state.ExpenseBucket);

    return <>
        <ul className="list-group mt-2">

            {Object.keys(ExpenseBucket.sections).map(e => {
                return <>
                    <ExpenseBucketSection forceTreeReload={props.forceTreeReload} uuid={e} />
                </>
            })}
        </ul>

        <div className="btn btn-sm btn-primary mt-2" onClick={() => {dispatch(add_section())}}>Neuer Abschnitt</div>
    </>
} 

export default ExpenseBucketModule;