let initialUuid = crypto.randomUUID();
const defaultState = {
    currentSection : "first-section-initial",
    addedIds : [],
    sections : {
        "first-section-initial" : {
            name : "Neuer Abschnitt",
            expenses : []
        }
    }
};

const ExpenseBucket = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case "ADD_SECTION":

            let uuu = crypto.randomUUID();

            newState.sections[uuu] = {
                name : "Neuer Abschnitt",
                expenses : []
            };

            if(Object.values(newState.sections).length == 1){
                newState.currentSection = uuu;
            }

            return newState;

        case "RENAME_SECTION":
            newState.sections[action.payload.uuid].name = action.payload.newName;
            return newState;

        case "SET_CURRENT_SECTION":
            newState.currentSection = action.payload.uuid
            return newState;

        case "ADD_ENTRY":
            newState.sections[newState.currentSection].expenses.push(action.payload);
            newState.addedIds.push(action.payload.id);
            return newState;

        case "REMOVE_ENTRY":
            Object.keys(newState.sections).forEach(sectionUUID => {
                let section = newState.sections[sectionUUID];
                section.expenses = section.expenses.filter(expense => expense.id !== action.payload.id);
            });
            
            newState.addedIds = newState.addedIds.filter(id => id !== action.payload.id);
            return newState;
            
        default:
            return newState;
    }
};

export default ExpenseBucket;
