import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import SyncConstants from "../../constants/SyncConstants";
import { copy } from "../../libs/SlotUtils";
import ExpenseEntryList from "./ExpenseEntryList";
import stylelist from "stylelist";

const MinutesToTime = (props) => {
    return <>
         {Math.floor(props.minutes / 60).toString().padStart(2,"0")}:{(props.minutes % 60).toString().padStart(2,"0")}
         </>
}

export default MinutesToTime;