import {combineReducers} from "redux";
import SlotReducer from "./SlotReducer";
import UserReducer from "./UserReducer";
import TeamReducer from "./TeamReducer";
import SyncReducer from "./SyncReducer";
import GladosReducer from "./GladosReducer";
import ExpenseBucket from "./ExpenseBucketReducer";

const RootReducer = combineReducers({
    User : UserReducer,
    Slot : SlotReducer,
    Team : TeamReducer,
    Sync : SyncReducer,
    Glados : GladosReducer,
    ExpenseBucket : ExpenseBucket
});

export default RootReducer;