export const add_weclapp_order = (weclappOrderId, gladosData, sync = false) => {
    return {
        type : "ADD_WECLAPP_ORDER",
        weclappOrderId : weclappOrderId,
        gladosData : gladosData
    }
} 

export const set_all_weclapp_orders = (allWeclappData) => {
    return {
        type : "SET_ALL_WECLAPP_ORDERS",
        payload : allWeclappData
    }
} 