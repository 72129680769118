const defaultState = {
    loginStatus : "offline",
    syncStatus : "idle",
    enableSync : true,
    notification : {
        type : "none",
        message : "",
        counter: 0
    }
};

const SyncReducer = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case "SET_LOGIN_STATUS":
            newState["loginStatus"] = action.newStatus;
            return newState;

        case "SET_SYNC_STATUS":
            newState["syncStatus"] = action.newStatus;
            return newState;

        case "ENABLE_SYNC":
            newState["enableSync"] = true;
            return newState;

        case "DISABLE_SYNC":
            newState["enableSync"] = false;
            return newState;

        case "SET_NOTIFICATION":
            newState["notification"] = {
                type : action.payload.type,
                message : action.payload.message,
                counter : (newState["notification"].counter) + 1
            };
            return newState;
            
        default:
            return newState;
    }
};

export default SyncReducer;