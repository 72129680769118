import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { set_sync_status } from "../../actions/Sync";
import { getSlotName } from "../../libs/SlotUtils";
import AddSlot from "../Slots/AddSlot";
import Expenses from "../Expenses/ExpenseProject"
import stylelist from "stylelist";
import ExpenseBucketModule from "./ExpenseBucketModule";

const ExpensesPage = (props) => {

    const UserSettings = useSelector((state) => state.User);
    const ExpenseBucket = useSelector((state) => state.ExpenseBucket);
    const [showExpenseBucket, setShowExpenseBucket] = useState(false);
    const [expenseProjectId, setExpenseProjectId] = useState(1);

    const forceReload = () => {
        setExpenseProjectId(0);
        setExpenseProjectId(1);
    }

    return (
        <>
            <div className={stylelist(
                        [showExpenseBucket, "container-fluid ps-5 pe-5 mt-5"],
                        [!showExpenseBucket, "container mt-5"]
                    )}>
                <div className="row">
                    <div className={stylelist(
                        [showExpenseBucket, "col-8"],
                        [!showExpenseBucket, "col-12"]
                    )}>
                        {UserSettings.betaEnabled &&
                            <>
                                <Expenses
                                    onProjectSelect={p => { debug(p) }}
                                    expenseProjectId={UserSettings.currentExpenseProjectRootId}
                                    level="1"
                                    filterDateFrom={false}
                                    filterDateTo={false}
                                    filterPerson={false}
                                    selectMode={false}
                                    onSelect={(e) => { console.log(e) }}
                                    projectEditMode={false}
                                    expenseBucket={showExpenseBucket}
                                    setShowExpenseBucket={setShowExpenseBucket}
                                />

                            </>
                        }

                        {!UserSettings.betaEnabled &&
                            <>
                                <h1>Arbeitsaufwände</h1>
                                <p>Diese Funktion ist aktuell noch im internen Test. Aktiviere den NG-Funktionstest, um sie zu nutzen.</p>
                            </>
                        }
                    </div>

                    {showExpenseBucket && 
                        <div className="col-3 offset-1">
                            <h1>Expense-Bucket</h1>
                            <ExpenseBucketModule 
                                forceTreeReload={() => {forceReload()}}
                            />

                        </div>
                    }
                </div>

                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </div>
        </>
    );
};

export default ExpensesPage;
