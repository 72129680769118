import moment from "moment";

export const getCalendarWeekForDate = (date) => {
    return moment(date).week();
}

export const getMonday = (date) => {
   return moment(date).startOf("week");
}
 
export const getFriday = (date) => {
    return moment(date).endOf("week").subtract(2, "days");
 }
 