const defaultState = {};
  
  const GladosReducer = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case "ADD_WECLAPP_ORDER":
          newState[action.weclappOrderId] = action.gladosData;  
          return newState;

      case "SET_ALL_WECLAPP_ORDERS":
        newState = action.payload;
        if(typeof newState == "undefined"){
          return null;
        }
        return newState;
        
      default:
        return newState;
    }
  };
  
  export default GladosReducer;
  