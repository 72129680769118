import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import SyncConstants from "../../constants/SyncConstants";
import { copy } from "../../libs/SlotUtils";
import PersonLabelWithDynamicInfo from "../Team/PersonLabelWithDynamicInfo";
import MinutesToTime from "./MinutesToTime";

const ConfirmButton = (props) => {
    const [firstClick, setFirstClick] = useState(false);

    return <>
        <div className="confirm-button text-center">
            {!firstClick &&
                <>
                    <a onClick={e =>setFirstClick(true)} className={props.className}>{props.label}</a>
                </>
            }

            {firstClick &&
                <>
                    <a onClick={ e => {props.onConfirm() ; setFirstClick(false)}} className={props.className}>{props.labelConfirm}</a><br />
                    <a className="small muted text-secondary cursor-pointer mt-2" onClick={ e=> {setFirstClick(false)}}>Abbruch </a>
                </>
            }
        </div>
    </>
}  

export default ConfirmButton;