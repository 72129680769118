export const add_new_slot = (slotId, slotData, sync = false) => {
    return {
        type : "ADD_NEW_SLOT",
        slotId : slotId,
        slotData : slotData,
        sync : sync
    }
}

export const update_slot = (slotId, slotData) => {
    return {
        type : "UPDATE_SLOT",
        slotId : slotId,
        slotData : slotData
    }
}

export const empty_slot_cache = () => {
    return {
        type : "EMPTY_SLOT_CACHE"
    }
}

export const copy_slot = (oldSlotId, newSlotId) => {
    return {
        type : "COPY_SLOT",
        oldSlotId : oldSlotId,
        newSlotId : newSlotId
    }
}

export const delete_slot = (slotId) => {
    return {
        type : "DELETE_SLOT",
        slotId : slotId
    }
}

export const move_slot = (oldSlotId, newSlotId) => {
    return {
        type : "MOVE_SLOT",
        oldSlotId : oldSlotId,
        newSlotId : newSlotId
    }
}

export const add_multiple_slots = (slots) => {
    return {
        type : "ADD_MULTIPLE_SLOTS",
        slots : slots
    }
}

export const toggle_slot_expenses_booked = (slotId) => {
    return {
        type : "TOGGLE_SLOT_EXPENSES_BOOKED",
        slotId : slotId
    }
}