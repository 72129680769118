export const add_section = (message, type) => {
    return {
        type : "ADD_SECTION",
        payload : {
            message : message,
            type : type 
        }
    }
}

export const rename_section = (uuid, newName) => {
    return {
        type : "RENAME_SECTION",
        payload : {
            uuid : uuid,
            newName : newName 
        }
    }
}

export const set_current_section = (uuid) => {
    return {
        type : "SET_CURRENT_SECTION",
        payload: {
            uuid: uuid
        }
    }
}

export const add_entry = (entry) => {
    return {
        type : "ADD_ENTRY",
        payload : entry
    }
}

export const remove_entry = (id) => {
    return {
        type : "REMOVE_ENTRY",
        payload : { id : id }
    }
}