import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useSlots = () => {
	// Connect to Redux-Slots
	const Slots = useSelector((state) => state.Slot);

	let returnObject = {
			getAll : () => {
				return Slots;
			},

			getAllAsArray : () => {
				return Object.values(Slots);
			},

			getBySlotId : (SlotId) => {
				if (typeof (Slots[SlotId]) != "undefined") {
					return Slots[SlotId];
				}else {
					return null;
				}
			},

			exists : (SlotId) => {
				if (typeof (Slots[SlotId]) != "undefined") {
					return true;
				}else {
					return false;
				}
			},

			getAmount : () => {
				return Object.values(Slots).length;
			}
		}
		return returnObject;
	}


export default useSlots