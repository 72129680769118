import React from "react";
import { useSelector } from "react-redux";

const UserSelector = (props) => {
    const Team = useSelector((state) => state.Team);

    return <>

        <select className="form-control" onChange={(event) => {props.onChange(event.target.value)}} value={props.value}>
            <option value="teamleader">Niemand (Teamleiter)</option>
            {Object.values(Team).map(TeamMember => {
                if(TeamMember.role == "administrator" && props.role =="administrator"){
                    return <option value={TeamMember.userName}>{TeamMember.realName}</option>
                }

                if (props.role !="administrator") {
                    return <option value={TeamMember.userName}>{TeamMember.realName}</option>
                }
            })}
        </select>
    </>

}

export default UserSelector


