export const set_login_status = (newStatus) => {
    return {
        type : "SET_LOGIN_STATUS",
        newStatus : newStatus
    }
}

export const set_sync_status = (newStatus, background=false) => {
    return {
        type : "SET_SYNC_STATUS",
        newStatus : newStatus
    }
}

export const enable_sync = () => {
    return {
        type : "ENABLE_SYNC"
    }
}

export const disable_sync = () => {
    return {
        type : "DISABLE_SYNC"
    }
}

export const set_notification = (message, type) => {
    return {
        type : "SET_NOTIFICATION",
        payload : {
            message : message,
            type : type 
        }
    }
}