const defaultState = {};

const TeamReducer = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case "ADD_OR_UPDATE_USER":
            newState[action.username] = JSON.parse(JSON.stringify(action.payload));
            return newState;
            

        default:
            return newState;
    }
};

export default TeamReducer;
