import React, { useEffect, useState } from "react"; import { CSSTransition } from "react-transition-group";

import stylelist from "stylelist";

const SharkIf = (props) => {
	const [animationShouldRender, setAnimationShouldRender] = useState(false);

	useEffect(() => {
		setAnimationShouldRender(false);
		let timeout1;
		if (props.animationDelay > 0 && props.show) {
			timeout1 = window.setTimeout(() => {
				setAnimationShouldRender(true);
			}, props.animationDelay);
		}

		return () => {
			clearTimeout(timeout1);
		}
	}, [props.animationDelay, props.show]);

	return (
		<>
			{props.animate && props.show && (
				<CSSTransition in={animationShouldRender} timeout={400} classNames={props.animation}>
					<div className={props.animation + "-enter sharkif"}>{props.children}</div>
				</CSSTransition>
			)}

			{((!props.animate) && props.show) && <>{props.children}</>}
		</>
	);
};

export default SharkIf;
