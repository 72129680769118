import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getSlotName } from "../libs/SlotUtils";
import { combineReducers } from "redux";


const useSlotStats = () => {

    const Slots = useSelector((state) => state.Slot);
    let returnObject = {


        getNextFreeSlot: (username, startDate) => {
            let currentDate = moment(startDate);
            let foundNextFreeSlot = false;
            let counter = 0;

        
            while((!foundNextFreeSlot) && counter < 101){

                currentDate = currentDate.add("1", "day");
                counter++;

                let checkSlotName1 = getSlotName(currentDate.toDate(), username, 1);
                let checkSlotName2 = getSlotName(currentDate.toDate(), username, 2);

                if(currentDate.day() == 0 || currentDate.day() == 6){
       
                    continue;
                }

                if(typeof(Slots[checkSlotName1]) == "undefined"){
           
                    return checkSlotName1;
                }

                if(typeof(Slots[checkSlotName2]) == "undefined"){
          
                    return checkSlotName2;
                }
            }
        },

        getSlotCounts: (orderId) => {
            let counter = {
                global: 0,
                past: 0,
                future: 0
            };

            Object.values(Slots).filter(slot => {try{ return slot.content.slotWeclappOrderId == orderId; } catch(e){}}).forEach(slot => {
                try {
                    counter.global++;
                    if (moment(slot.meta.slotDate, "YYYY-MM-DD").diff() < 0 && slot.content.slotWeclappOrderId == orderId) {
                        counter.past++;
                    }

                    if (moment(slot.meta.slotDate, "YYYY-MM-DD").diff() > 0 && slot.content.slotWeclappOrderId == orderId) {
                        counter.future++;
                    }
                } catch (e) {

                }
            });

            return counter;
        },

        getTotalSlotCount : (orderId) => {
            let counter = 0;
            
            Object.values(Slots).filter(slot => {slot.content.slotWeclappOrderId == orderId}).forEach(slot => {
                try {
                   counter++;
                } catch (e) {

                }
            });

            return counter;
        },

        getSlotCountsForUser: (userName) => {
            let counter = {
                global: 0,
                past: 0,
                future: 0,
                currentWeek : 0,
                nextWeek : 0,
                nextMonth : 0
            };

            Object.values(Slots).filter(slot => slot.meta.slotUser == userName).forEach(slot => {
                try {
                    counter.global++

                    let momentInstance = moment(slot.meta.slotDate, "YYYY-MM-DD");
                    if (momentInstance.diff() < 0 && slot.meta.slotUser == userName) {
                        counter.past++;
                    }

                    if (momentInstance.diff() > 0 && slot.meta.slotUser == userName) {
                        counter.future++;
                    }

                    if (momentInstance.isSame(moment().add(1, "week") , "week") && slot.meta.slotUser == userName) {
                        counter.nextWeek++;
                    }

                    if (momentInstance.isSame(moment().add(1, "month") , "month") && slot.meta.slotUser == userName) {
                        counter.nextMonth++;
                    }

                    if (momentInstance.isSame(new Date(), "week") && slot.meta.slotUser == userName) {
                        counter.currentWeek++;
                    }
                } catch (e) {}
            });

            return counter;
        }
    }

    return returnObject;
}

export default useSlotStats