import axios from "axios";
import { add_new_slot } from "../actions/Slots";
import { enable_sync, set_login_status, set_notification } from "../actions/Sync";
import SyncConstants from "../constants/SyncConstants";
import { copy, getSlotMetaFromId } from "../libs/SlotUtils";

/**
 * SyncMiddleware for Redux
 * Imagine this as a kind of a second reducer which transforms
 * local dispatched actions into API-calls on our backend.
 *
 */
const SyncMiddleware = store => next => action => {

    // Send ADD_NEW_SLOT-Actions to our server
    if ((action.type == "ADD_NEW_SLOT" && action.sync) || action.type == "UPDATE_SLOT") {
        axios({
            method: "post",
            auth: {
                username: store.getState().User.userName,
                password: store.getState().User.syncToken
            },
            url: SyncConstants["syncBaseURL"] + "slot",
            responseType: 'json',
            data: action.slotData
        }).then((response) => {
            if (action.type == "ADD_NEW_SLOT") {
                store.dispatch(set_notification("Slot erfolgreich angelegt", "success"));
                store.dispatch(enable_sync());
            }else {
                store.dispatch(set_notification("Slot erfolgreich bearbeitet", "success"));
                store.dispatch(enable_sync());
            }
        }).catch(response => {
            store.dispatch(set_notification("Der Slot konnte nicht angelegt werden. Der Server antwortet mit einem allgemeinen Fehler - möglicherweise ist die Datenbank nicht erreichbar.", "error"));
            store.dispatch(enable_sync());
        });
    }

    // Send ADD_NEW_SLOT-Actions to our server
    if (action.type == "COPY_SLOT") {
        let newSlot = copy(store.getState().Slot[action.oldSlotId]);
        newSlot.meta = getSlotMetaFromId(action.newSlotId);
        newSlot.content.slotId = newSlot.meta.slotId;
        newSlot._id = newSlot.meta.slotId;
        delete newSlot._rev;

        axios({
            method: "post",
            auth: {
                username: store.getState().User.userName,
                password: store.getState().User.syncToken
            },
            url: SyncConstants["syncBaseURL"] + "slot",
            responseType: 'json',
            data: newSlot
        }).then((response) => {
            store.dispatch(set_notification("Slot erfolgreich kopiert.", "success" ));
            store.dispatch(enable_sync());

        }).catch(response => {
            store.dispatch(set_notification("Der Slot konnte nicht kopiert werden. Der Server antwortet mit einem allgemeinen Fehler - möglicherweise ist die Datenbank nicht erreichbar.", "error"));
            store.dispatch(enable_sync());
        });
    }

        // Send ADD_NEW_SLOT-Actions to our server
        if (action.type == "MOVE_SLOT") {
            let newSlot = copy(store.getState().Slot[action.oldSlotId]);
            newSlot.meta = getSlotMetaFromId(action.newSlotId);
            newSlot.content.slotId = newSlot.meta.slotId;
            newSlot._id = newSlot.meta.slotId;
            delete newSlot._rev;
    
            axios({
                method: "post",
                auth: {
                    username: store.getState().User.userName,
                    password: store.getState().User.syncToken
                },
                url: SyncConstants["syncBaseURL"] + "slot",
                responseType: 'json',
                data: newSlot
            }).then((response) => {

                // Slot was copied successfully
                axios({
                    method: "delete",
                    auth: {
                        username: store.getState().User.userName,
                        password: store.getState().User.syncToken
                    },
                    url: SyncConstants["syncBaseURL"] + "slot",
                    responseType: 'json',
                    data: {slotId : action.oldSlotId}
                }).then((response) => {
                    store.dispatch(set_notification("Der Slot wurde erfolgreich verschoben.", "success"));
                    store.dispatch(enable_sync());
                }).catch(response => {
                    store.dispatch(set_notification("Der ursprüngliche Slot konnte nicht gelöscht werden. Der Server antwortet mit einem allgemeinen Fehler - möglicherweise ist die Datenbank nicht erreichbar.", "error"));
                    store.dispatch(enable_sync());
                });
    
            }).catch(response => {
                store.dispatch(set_notification("Der Slot konnte nicht verschoben werden (Fehler vom Server).", "error"));
                store.dispatch(enable_sync());
            });
        }

    // Send ADD_NEW_SLOT-Actions to our server
    if (action.type == "DELETE_SLOT") {
        axios({
            method: "delete",
            auth: {
                username: store.getState().User.userName,
                password: store.getState().User.syncToken
            },
            url: SyncConstants["syncBaseURL"] + "slot",
            responseType: 'json',
            data: {slotId : action.slotId}
        }).then((response) => {
            store.dispatch(set_notification("Slot gelöscht", "success"));
            store.dispatch(enable_sync());
        }).catch(response => {
            store.dispatch(set_notification("Der Slot konnte nicht gelöscht werden. Der Server antwortet mit einem allgemeinen Fehler - möglicherweise ist die Datenbank nicht erreichbar.", "error"));
            store.dispatch(enable_sync());
        });
    }

    return next(action);
}

export default SyncMiddleware 