import { copy, getSlotMetaFromId } from "../libs/SlotUtils";

const defaultState = {};

const SlotReducer = (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {

    case "ADD_NEW_SLOT":
      newState[action.slotId] = action.slotData;
      return newState;

    case "EMPTY_SLOT_CACHE":
      newState = {};
      return newState;

    case "ADD_MULTIPLE_SLOTS":
  
      Object.values(action.slots).forEach(singleSlot => {
  
        newState[singleSlot.slot_id] = {
          meta : getSlotMetaFromId(singleSlot.slot_id),
          content : singleSlot.slot_data.content
        }
      });

    return newState

    case "UPDATE_SLOT":
      newState[action.slotId] = action.slotData;
      return newState;

    case "COPY_SLOT":
      newState[action.newSlotId] = {
        meta: getSlotMetaFromId(action.oldSlotId),
        content: copy(newState[action.oldSlotId].content)
      }

      return newState;

    case "MOVE_SLOT":
      newState[action.newSlotId] = {
        meta: getSlotMetaFromId(action.oldSlotId),
        content: copy(newState[action.oldSlotId].content)
      }

      delete newState[action.oldSlotId];
      return newState;

    case "TOGGLE_SLOT_EXPENSES_BOOKED":
      newState[action.slotId].content.slotExpensesBooked = !newState[action.slotId].content.slotExpensesBooked;
      return newState;

    case "DELETE_SLOT":
      delete newState[action.slotId];
      return newState;

    default:
      return newState;
  }
};

export default SlotReducer;