import React, { useEffect, useRef, useState } from "react";
import stylelist from "stylelist";
import useSlots from "../../hooks/useSlots";

const SlotInputField = (props) => {

    // Checks, if we´ve already executed the calculation of all available autocomplete-suggestions
    const [firstRunDone, setFirstRunDone] = useState(false);

    // The current autocomplete list, filtered
    const [autoCompleteList, setAutoCompleteList] = useState([]);

    // The list with all autocomplete-suggestions
    const [allAutoCompleteList, setAllAutoCompleteList] = useState([]);

    const [showAutoComplete, setShowAutoComplete] = useState(false);

    const inputFieldRef = useRef();

    // Indicates if a Title is a project-title
    const [isProject, setIsProject] = useState(false);

    // Use Slots (heh)
    const Slots = useSlots(); 


    // Executed after the popup is rendered
    // We parse all Slot-Titles and split them at //
    useEffect(() => {
        let allSlotProjects = [];
        
        if (!firstRunDone && Slots.getAmount() > 1) {
            Slots.getAllAsArray().forEach((slot) => {
                try {
                    let newWord = slot.content.slotTitle.split(" // ")[0];
                    if (allSlotProjects.indexOf(newWord) < 0) {
                        allSlotProjects.push(newWord);
                    }
                } catch (e) { }
            });

            setAllAutoCompleteList(allSlotProjects);
        }
        setFirstRunDone(true);
    }, [firstRunDone]);

    // When input is changed, we compare the input against the list
    useEffect(() => {
        let tempList = [];

        allAutoCompleteList.forEach((entry) => {
            if (entry.toLowerCase().startsWith(props.value.toLowerCase())) {
                tempList.push(entry);
            }
        });

        setAutoCompleteList(tempList);
        setShowAutoComplete(true);
    }, [props.value]);

    // Check, if the title contains //
    useEffect(() => {
        if(props.value.indexOf("//") >= 0){
            setIsProject(true);
        }else {
            setIsProject(false);
        }
    }, [props.value]);

    const onGroupButtonPressed = () => {
        
        // Is currently a project, disable this
        if(isProject){
            props.onChange(props.value.substring( 0, props.value.indexOf( " //" ) ));
            setIsProject(false);
        }else {
            props.onChange(props.value + " // ");
            inputFieldRef.current.focus();
            setIsProject(true);
        }

        setShowAutoComplete(false);
    }


    return <>
        <div className="input-group mb-2">
            <div onClick={() => {onGroupButtonPressed();}} className={stylelist("input-group-text cursor-pointer", [isProject, "bg-red text-white"])}>//</div>
            <input ref={inputFieldRef} type="text" className="form-control" value={props.value} onChange={(event) => {props.onChange(event.target.value)}} />
        </div>
        
        {(props.value.length >= 1 && props.value.indexOf("//" < 0) ) && 
            <>
                {autoCompleteList.
                    filter((entry) => {return entry.indexOf("&") < 0}).
                    slice(0,5).
                    map(listEntry => {
                        return <>
                            <small key={listEntry} className="badge bg-secondary cursor-pointer me-1 mb-1" onClick={() => {props.onChange(listEntry + " // ") ; inputFieldRef.current.focus()}}>{listEntry} // </small>
                        </>
                    })
                }
            </>
            }
    </>
}

export default SlotInputField;