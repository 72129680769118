import React from "react";
import * as ReactDOM from "react-dom/client";
import Slotify from "./components/Slotify"
import {BrowserRouter as Router} from "react-router-dom";
import {createStore, applyMiddleware, compose } from "redux";
import {Provider} from "react-redux";
import SyncMiddleware from "./middleware/Sync";
import RootReducer from "./reducer";

document.addEventListener("DOMContentLoaded", () => {
    let store = createStore(RootReducer, {} , compose(applyMiddleware (SyncMiddleware)));
    let root = ReactDOM.createRoot(document.getElementById("app"));

    root.render(<Provider store={store}><Router><Slotify /></Router></Provider>);
});